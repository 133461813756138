<template>

  <div>
    <div class="container-100 class-texture" id="fix-header-vega"
         :style="{'background-color': sfondo_1 }"
         v-if="sfondo_1 !== null">

      <div v-if="texture" class="texture">
        <img :src="texture" alt="">
      </div>

      <div class=" container-80">

        <div class="navbar-menu" id="scroll-header-vega"
        >

          <!-- Hamburger mobile -->
          <div id="hmg-icon"
               class="hmg-menu">
            <MenuHamburger
                :style="{'fill': 'white'}"
                class="icon-hamburger"
                @click="toogleMenu">
            </MenuHamburger>
            <div @click="toogleMenu" id="all-item-menu" class="item-visibility-none"
                 :style="{'color': testo_sfondo_1 + '!important'}">
              <h3>
                <router-link to="/" :style="{'color': testo_sfondo_1 + '!important'}">Home</router-link>
              </h3>
              <h3>
                <router-link to="/chi-siamo" :style="{'color': testo_sfondo_1 + '!important'}">Chi siamo</router-link>
              </h3>
              <h3>
                <router-link to="/manifesti" :style="{'color': testo_sfondo_1 + '!important'}">Necrologi</router-link>
              </h3>
              <h3 v-if="faq !== null && faq.length > 0">
                <router-link to="/faq" :style="{'color': testo_sfondo_1 + '!important'}">FAQ</router-link>
              </h3>
              <h3>
                <router-link to="/contatti" :style="{'color': testo_sfondo_1 + '!important'}">Contatti</router-link>
              </h3>
              <h3 v-if="casa_funeraria.length > 0">
                <b-dropdown
                    text="Case Funerarie"
                    class="custom-dropdown-case-funerarie nav-casa-funeraria-mobile"
                    right
                    :style="{'color': testo_sfondo_1 + '!important'}">
                  <b-dropdown-item
                      :style="{'color': testo_sfondo_1 + '!important'}">
                    <router-link v-for="item in casa_funeraria" :key="item.id"
                                 class="nav-casa-funeraria"
                                 :to="`/casa-funeraria/${item.id}`">Casa Funeraria {{ item.nome }}
                    </router-link>
                  </b-dropdown-item>
                </b-dropdown>
              </h3>
            </div>
          </div>
          <!-- /Hamburger mobile -->

          <!-- Navbar Desktop -->
          <div class="navbar-header">
            <ul class="item-navbar" :style="{'color': testo_sfondo_1 + '!important'}">
              <li>
                <router-link to="/">
                  <div class="logo-agency" id="logo-agency-header-vega"
                       :style="{ backgroundImage: 'url(' + logo_azienda + '-/stretch/fill/-/format/auto/' + ')' }">
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :style="{'color': testo_sfondo_1 + '!important'}" class="nav-home"
                             to="/">Home
                </router-link>
              </li>
              <li>
                <router-link :style="{'color': testo_sfondo_1 + '!important'}" class="nav-who-are"
                             to="/chi-siamo">
                  Chi siamo
                </router-link>
              </li>
              <li v-if="casa_funeraria.length > 0">
                <b-dropdown
                    text="CASA FUNERARIA"
                    class="custom-dropdown-case-funerarie"
                >
                  <b-dropdown-item
                      :style="{'color': testo_sfondo_2 + '!important','background-color': sfondo_2 + '!important'}">
                    <router-link v-for="item in casa_funeraria" :key="item.id"
                                 class="nav-casa-funeraria"
                                 :to="`/casa-funeraria/${item.id}`">Casa Funeraria {{ item.nome }}
                    </router-link>
                  </b-dropdown-item>
                </b-dropdown>
              </li>
              <li>
                <router-link :style="{'color': testo_sfondo_1 + '!important'}" class="nav-manifesti"
                             to="/manifesti">
                  Necrologi
                </router-link>
              </li>
              <li v-if="faq !== null && faq.length > 0">
                <router-link :style="{'color': testo_sfondo_1 + '!important'}" class="nav-faq"
                             to="/faq">FAQ
                </router-link>
              </li>
              <li>
                <router-link :style="{'color': testo_sfondo_1 + '!important'}" class="nav-contatti"
                             to="/contatti">
                  Contatti
                </router-link>
              </li>
            </ul>
          </div>
          <div class="item-navbar-telephone">
            <Telephone
                :style="{'fill': testo_sfondo_1 }"
                class="icon-telephone-mock2"
            >
            </Telephone>
            <span class="telephone-navbar" :style="{'color': testo_sfondo_1 + '!important'}"
                  @click="callNumberSelect(telefono)"
            >{{
                telefono
              }}</span>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuHamburger from '@/assets/TemplateImg/menu.svg?inline';
import {mapGetters} from "vuex";
import Telephone from '@/assets/TemplateImg/iconTel.svg?inline';

export default {
  name: "Header",
  components: {
    MenuHamburger,
    Telephone
  },
  data() {
    return {
      element: null,
      element_visibility: null,
      isMobileVersion: null,
      logo_agency_header: null,
      shrinkOn: null,
      distanceY: null,
      headerEl: null,
    }
  },
  computed: {
    ...mapGetters([
      'casa_funeraria',
      'logo_azienda',
      'flag_case_funerarie',
      'faq',
      'telefono',
      'texture',
      'sfondo_3',
      'sfondo_1',
      'sfondo_2',
      'testo_sfondo_1',
      'testo_sfondo_2',
      'testo_sfondo_3',
    ]),
  },

  methods: {

    toogleMenu: function () {
      this.element = document.getElementById("hmg-icon");
      this.element.classList.toggle("full-screen-menu");
      this.element.classList.toggle("hmg-menu");
      this.element_visibility = document.getElementById("all-item-menu");
      this.element_visibility.classList.toggle("item-visibility-block");
      this.element_visibility.classList.toggle("item-visibility-none");
      this.isMobileVersion = document.getElementsByClassName('full-screen-menu');
      if (this.isMobileVersion.length > 0) {
        document.body.style.position = "relative";
        document.body.style.overflow = "hidden";
        document.getElementById("hmg-icon").style.backgroundColor = this.sfondo_1;
      } else {
        document.body.style.removeProperty('position');
        document.body.style.removeProperty('overflow');
        document.getElementById("hmg-icon").style.backgroundColor = '';
      }
    },
    callNumberSelect(telephone) {
      document.location.href = "tel:" + telephone;
    },

  },

}
</script>


<style scoped>
@import '~@/assets/css/template_vega/header.css';
</style>
