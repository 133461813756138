import VueRouter from 'vue-router'
import axios from "axios";

//const API_GET_AGENZIA = '/dashboard/api/v0/azienda/view/template';
const API_GET_AGENZIA = '/dashboard/api/v0/azienda/view';

async function checkTemplate() {
    const res = await axios.get(API_GET_AGENZIA)
    const result = res.data.results[0]
    if (result.favicon !== null) {
        document.querySelector("link[rel~='icon']").href = result.favicon;
        document.querySelector("link[rel~='apple-touch-icon']").href = result.favicon;
    }
    result.template === null ? localStorage.setItem('template', 1) : localStorage.setItem('template', result.template.id)
    return result.template == null ? 1 : result.template.id
}

const HomePage = async () => {

    if (await checkTemplate() === 1) {
        return import(/* webpackChunkName: "homepage" */ '@/components/Homepage')
    }
    if (await checkTemplate() === 2) {
        return import(/* webpackChunkName: "homepage" */ '@/components/template_altair/Homepage')
    }

    if (await checkTemplate() === 3) {
        return import(/* webpackChunkName: "homepage" */ '@/components/template_vega/Homepage')
    }
}

const FAQ = async () => {

    if (await checkTemplate() === 1) {
        return import(/* webpackChunkName: "faq" */ '@/components/Faq')
    }

    if (await checkTemplate() === 2) {
        return import(/* webpackChunkName: "faq" */ '@/components/template_altair/Faq')
    }

    if (await checkTemplate() === 3) {
        return import(/* webpackChunkName: "faq" */ '@/components/template_vega/Faq')
    }
}

const Contatti = async () => {

    if (await checkTemplate() === 1) {
        return import(/* webpackChunkName: "contatti" */ '@/components/Contatti')
    }

    if (await checkTemplate() === 2) {
        return import(/* webpackChunkName: "contatti" */ '@/components/template_altair/Contatti')
    }

    if (await checkTemplate() === 3) {
        return import(/* webpackChunkName: "contatti" */ '@/components/template_vega/Contatti')
    }
}

const ChiSiamo = async () => {

    if (await checkTemplate() === 1) {
        return import(/* webpackChunkName: "chisiamo" */ '@/components/ChiSiamo')
    }

    if (await checkTemplate() === 2) {
        return import(/* webpackChunkName: "chisiamo" */ '@/components/template_altair/ChiSiamo')
    }

    if (await checkTemplate() === 3) {
        return import(/* webpackChunkName: "chisiamo" */ '@/components/template_vega/ChiSiamo')
    }

}

const Manifesti = async () => {

    if (await checkTemplate() === 1) {
        return import(/* webpackChunkName: "manifesti" */ '@/components/Manifesti')
    }

    if (await checkTemplate() === 2) {
        return import(/* webpackChunkName: "manifesti" */ '@/components/template_altair/Manifesti')
    }

    if (await checkTemplate() === 3) {
        return import(/* webpackChunkName: "manifesti" */ '@/components/template_vega/Manifesti')
    }


}

const CasaFuneraria = async () => {

    if (await checkTemplate() === 1) {
        return import(/* webpackChunkName: "casafuneraria" */ '@/components/CasaFuneraria')
    }

    if (await checkTemplate() === 2) {
        return import(/* webpackChunkName: "casafuneraria" */ '@/components/template_altair/CasaFuneraria')
    }

    if (await checkTemplate() === 3) {
        return import(/* webpackChunkName: "casafuneraria" */ '@/components/template_vega/CasaFuneraria')
    }
}

const DettaglioManifesto = async () => {

    if (await checkTemplate() === 1) {
        return import(/* webpackChunkName: "dettagliomanifesto" */ '@/components/DettaglioManifesto')
    }

    if (await checkTemplate() === 2) {
        return import(/* webpackChunkName: "dettagliomanifesto" */ '@/components/template_altair/DettaglioManifesto')
    }

    if (await checkTemplate() === 3) {
        return import(/* webpackChunkName: "dettagliomanifesto" */ '@/components/template_vega/DettaglioManifesto')
    }
}

const DettaglioNecrologio = async () => {

    if (await checkTemplate() === 1) {
        return import(/* webpackChunkName: "dettaglionecrologio" */ '@/components/DettaglioNecrologio')
    }

    if (await checkTemplate() === 2) {
        return import(/* webpackChunkName: "dettaglionecrologio" */ '@/components/template_altair/DettaglioNecrologio')
    }

    if (await checkTemplate() === 3) {
        return import(/* webpackChunkName: "dettaglionecrologio" */ '@/components/template_vega/DettaglioNecrologio')
    }

}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'Homepage',
            component: HomePage,
            meta: {
                sitemap: {
                    priority: 0.8,
                    changefreq: 'weekly',
                }
            }
        },
        {
            path: '/chi-siamo',
            name: 'ChiSiamo',
            component: ChiSiamo,
            meta: {
                sitemap: {
                    priority: 0.8,
                    changefreq: 'weekly',
                }
            }
        },
        {
            path: '/manifesti',
            name: 'Manifesti',
            component: Manifesti,
            meta: {
                sitemap: {
                    priority: 0.8,
                    changefreq: 'weekly',
                }
            }
        },
        {
            path: "/manifesto/:id",
            name: "DettaglioManifesto",
            component: DettaglioManifesto,
        },
        {
            path: "/necrologio/:id",
            name: "DettaglioNecrologio",
            component: DettaglioNecrologio,
        },
        {
            path: "/contatti",
            name: "Contatti",
            component: Contatti,
        },
        {
            path: '/faq',
            name: 'Faq',
            component: FAQ,
        },
        {
            path: '/casa-funeraria/:id',
            name: 'CasaFuneraria',
            component: CasaFuneraria,
        },
        {
            path: "*",
            name: "404",
            component: HomePage,
        },
    ]
})
export default router;
