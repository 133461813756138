<template>

  <div>
    <div id="double-header" :style="{'background-color': sfondo_2, 'color': testo_sfondo_2 }">
      <span @click="callNumberSelect(telefono)">{{ telefono }}</span>
    </div>
    <div class="container-100 class-texture" id="fix-header"
         :style="{'background-color': sfondo_1 }"
         v-if="sfondo_1 !== null">

      <div v-if="texture" class="texture">
        <img :src="texture" alt="">
      </div>

      <div class=" container-80">

        <div class="navbar-menu" id="scroll-header"
        >
          <router-link to="/">
            <div class="logo-agency" id="logo-agency-header"
                 :style="{ backgroundImage: 'url(' + logo_azienda + '-/stretch/fill/-/format/auto/' + ')' }">
            </div>
          </router-link>

          <!-- Hamburger mobile -->
          <div id="hmg-icon"
               class="hmg-menu">
            <MenuHamburger
                :style="{'fill': 'white'}"
                class="icon-hamburger"
                @click="toogleMenu">
            </MenuHamburger>
            <div @click="toogleMenu" id="all-item-menu" class="item-visibility-none"
                 :style="{'color': testo_sfondo_1 + '!important'}">
              <h3>
                <router-link to="/" :style="{'color': testo_sfondo_1 + '!important'}">Home</router-link>
              </h3>
              <h3>
                <router-link to="/chi-siamo" :style="{'color': testo_sfondo_1 + '!important'}">Chi siamo</router-link>
              </h3>
              <h3>
                <router-link to="/manifesti" :style="{'color': testo_sfondo_1 + '!important'}">Necrologi</router-link>
              </h3>
              <h3 v-if="faq !== null && faq.length > 0">
                <router-link to="/faq" :style="{'color': testo_sfondo_1 + '!important'}">FAQ</router-link>
              </h3>
              <h3>
                <router-link to="/contatti" :style="{'color': testo_sfondo_1 + '!important'}">Contatti</router-link>
              </h3>
              <h3 v-if="casa_funeraria.length > 0">
                <b-dropdown
                    text="Case Funerarie"
                    class="custom-dropdown-case-funerarie nav-casa-funeraria-mobile"
                    right
                    :style="{'color': testo_sfondo_1 + '!important'}">
                  <b-dropdown-item
                      :style="{'color': testo_sfondo_1 + '!important'}">
                    <router-link v-for="item in casa_funeraria" :key="item.id"
                                 class="nav-casa-funeraria"
                                 :to="`/casa-funeraria/${item.id}`">Casa Funeraria {{ item.nome }}
                    </router-link>
                  </b-dropdown-item>
                </b-dropdown>
              </h3>
            </div>
          </div>
          <!-- /Hamburger mobile -->

          <!-- Navbar Desktop -->
          <div class="navbar-header">
            <ul class="item-navbar" :style="{'color': testo_sfondo_1 + '!important'}">
              <li>
                <router-link :style="{'color': testo_sfondo_1 + '!important'}" class="nav-home"
                             to="/">Home
                </router-link>
              </li>
              <li>
                <router-link :style="{'color': testo_sfondo_1 + '!important'}" class="nav-who-are"
                             to="/chi-siamo">
                  Chi siamo
                </router-link>
              </li>
              <li v-if="casa_funeraria.length > 0">
                <b-dropdown
                    text="CASA FUNERARIA"
                    class="custom-dropdown-case-funerarie"
                >
                  <b-dropdown-item
                      :style="{'color': testo_sfondo_2 + '!important','background-color': sfondo_2 + '!important'}">
                    <router-link v-for="item in casa_funeraria" :key="item.id"
                                 class="nav-casa-funeraria"
                                 :to="`/casa-funeraria/${item.id}`">Casa Funeraria {{ item.nome }}
                    </router-link>
                  </b-dropdown-item>
                </b-dropdown>
              </li>
              <li>
                <router-link :style="{'color': testo_sfondo_1 + '!important'}" class="nav-manifesti"
                             to="/manifesti">
                  Necrologi
                </router-link>
              </li>
              <li v-if="faq !== null && faq.length > 0">
                <router-link :style="{'color': testo_sfondo_1 + '!important'}" class="nav-faq"
                             to="/faq">FAQ
                </router-link>
              </li>
              <li>
                <router-link :style="{'color': testo_sfondo_1 + '!important'}" class="nav-contatti"
                             to="/contatti">
                  Contatti
                </router-link>
              </li>
              <!-- <li class="item-navbar-telephone" @click="callNumberSelect(telefono)">
                <span class="telephone-navbar" :style="{'color': testo_sfondo_1 + '!important'}"
                >{{
                    telefono
                  }}</span>
              </li> -->
              <!-- <li>
                <img class="icon-lente-navbar" src="~@/assets/TemplateImg/lente.svg" alt="">
              </li> -->
            </ul>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuHamburger from '@/assets/TemplateImg/menu.svg?inline'
import {mapGetters} from "vuex";

export default {
  name: "Header",
  components: {
    MenuHamburger,
  },
  data() {
    return {
      element: null,
      element_visibility: null,
      isMobileVersion: null,
      logo_agency_header: null,
      shrinkOn: null,
      distanceY: null,
      headerEl: null,
    }
  },
  computed: {
    ...mapGetters([
      'casa_funeraria',
      'logo_azienda',
      'flag_case_funerarie',
      'faq',
      'telefono',
      'texture',
      'sfondo_3',
      'sfondo_1',
      'sfondo_2',
      'testo_sfondo_1',
      'testo_sfondo_2',
      'testo_sfondo_3',
    ]),
  },

  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {

    toogleMenu: function () {
      this.element = document.getElementById("hmg-icon");
      this.logo_agency_header = document.getElementById("logo-agency-header");
      this.logo_agency_header.style.zIndex = "1";
      this.element.classList.toggle("full-screen-menu");
      this.element.classList.toggle("hmg-menu");
      this.element_visibility = document.getElementById("all-item-menu");
      this.element_visibility.classList.toggle("item-visibility-block");
      this.element_visibility.classList.toggle("item-visibility-none");
      this.isMobileVersion = document.getElementsByClassName('full-screen-menu');
      if (this.isMobileVersion.length > 0) {
        document.body.style.position = "relative";
        document.body.style.overflow = "hidden";
        document.getElementById("hmg-icon").style.backgroundColor = this.sfondo_1;
      } else {
        document.body.style.removeProperty('position');
        document.body.style.removeProperty('overflow');
        document.getElementById("hmg-icon").style.backgroundColor = '';
      }
    },

    handleScroll() {
      if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
        document.getElementById("scroll-header").style.height = "50px";
        document.getElementById("fix-header").style.top = "0px";

        if (document.getElementById("logo-agency-header")) {
          document.getElementById("logo-agency-header").classList.add("logo-agency-scroll");
          document.getElementById("logo-agency-header").classList.remove("logo-agency");
        }

        if (document.getElementById("img-manifesti-background")) {
          document.getElementById("img-manifesti-background").style.marginTop = "0px";
        }

      } else {
        document.getElementById("scroll-header").style.height = "80px";
        document.getElementById("fix-header").style.top = "50px";

        if (document.getElementById("logo-agency-header")) {
          document.getElementById("logo-agency-header").classList.add("logo-agency");
          document.getElementById("logo-agency-header").classList.remove("logo-agency-scroll");
        }

        if (document.getElementById("img-manifesti-background")) {
          document.getElementById("img-manifesti-background").style.marginTop = "80px";
        }


      }
    },
    callNumberSelect(telephone) {
      document.location.href = "tel:" + telephone;
    },

  },

}
</script>


<style scoped>

.scroll-header {
  display: none;
}

#double-header {
  height: 50px;
  line-height: 50px;
}

#double-header span {
  margin-left: 20px;
  cursor: pointer;
}

/* .container-100 {
  background-image: linear-gradient(to right, #2E3192, #1C1565);
} */

#fix-header {
  position: fixed;
  z-index: 20000000000;
  width: 100%;
  top: 50px;
}

.container-80 {
  width: 80%;
  margin: auto;
  color: black !important;
}

.navbar-menu {
  position: relative;
  display: flex;
  display: -webkit-flex;
  -webkit-justify-content: flex-end;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.navbar-header {
  width: 75%;
  height: 100%;
  /* color: white; */
}

.texture > img {
  position: absolute;
  height: 100%;
  /* opacity: 0.1; */
  width: 100%;
  object-fit: cover;
}

.class-texture {
  position: relative;
}

a {
  text-decoration: none !important;
}

/* .nav-home, .nav-manifesti, .nav-who-are, .nav-contatti, .nav-casa-funeraria, .nav-faq {
  color: #8284BB !important;
} */

.router-link-exact-active {
  color: white !important;
}

li:hover:after {
  width: 100%;
  right: 0;
}

li:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  right: 0;
  background: white;
  transition: width 1s ease 0s, right 0.5s ease 0s;
  width: 0;
}

li:nth-child(7):after {
  display: none;
}

.item-navbar {
  font-size: 13px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  text-transform: uppercase;
}

.icon-telephone-navbar {
  height: 18px;
  vertical-align: text-bottom;
  margin-right: 6px;
}

/* .item-navbar-telephone {
  display: flex;
  align-items: center;
} */

.telephone-navbar {
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.icon-lente-navbar {
  height: 18px;
  vertical-align: top;
}

.logo-agency {
  width: 130px;
  height: 130px;
  margin-top: 80px;
  border: 3px solid white;
  border-radius: 25px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 100000;
}

.logo-agency-scroll {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  border: 3px solid white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 100000;
  border-radius: 10px;
}

#readAll {
  display: none;
}


@media (min-width: 1024px) {
  .nav {
    display: block !important;
  }

  .hmg-menu {
    display: none !important;
  }
}


@media (max-width: 1023px) {
  .nav {
    display: none !important;
  }

  .navbar-header {
    display: none;
  }

  .hmg-menu {
    display: block !important;
  }

  .icon-hamburger {
    width: 25px;
    height: 25px;
  }

  #__BVID__24__BV_toggle_ {
    font-size: 1.75rem;
    text-transform: uppercase;
  }

  .nav-casa-funeraria {
    font-size: 3.8vw;
  }

  .dropdown-menu {
    border: none !important;
    z-index: 0 !important;
  }

  .btn {
    padding: 0 !important;
  }

  .dropdown-item {
    padding: 0 !important;
  }

  .item-visibility-none {
    display: none;
  }

  .item-visibility-block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .full-screen-menu {
    /* background-image: linear-gradient(to top, #1C1565, #2E3192); */
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 10000;
    color: white;
    text-align: end;
    padding-right: 10%;
    padding-top: 27.5px;
    text-transform: uppercase;

  }

  #all-item-menu > h3 {
    margin-top: 30px;
  }

  .icon-telephone-navbar {
    width: 50px;
    height: 50px;
    margin-top: 30px;
  }
}


@media (max-width: 767.98px) {

  .container-100 {
    position: relative;
  }

  #__BVID__24__BV_toggle_ {
    font-size: 1.75rem;
  }

  .item-visibility-none {
    display: none;
  }

  .item-visibility-block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .logo-agency {
    width: 85px;
    height: 85px;
    border-radius: 15px;
  }

  .icon-hamburger {
    width: 25px;
    height: 25px;
  }

  .full-screen-menu {
    /* background-image: linear-gradient(to top, #1C1565, #2E3192); */
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 10000;
    color: white;
    text-align: end;
    padding-right: 10%;
    padding-top: 27.5px;
    text-transform: uppercase;
  }

  #all-item-menu > h3 {
    margin-top: 30px;
  }

  .icon-telephone-navbar {
    width: 50px;
    height: 50px;
    margin-top: 30px;
  }
}

ul.dropdown-menu.show {
  background-color: var(--sfondo_1) !important;
  color: var(--testo_sfondo_3) !important;
}

.custom-dropdown-case-funerarie > button.btn-secondary {
  color: var(--sfondo_1) !important;;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-secondary:not(:disabled):not(.disabled), .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  font-size: 13px !important;
}

@media (max-width: 1023px) {

  .btn-secondary:not(:disabled):not(.disabled), .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    font-size: 28px !important;
  }

  #__BVID__22__BV_toggle_ {
    font-size: 1.75rem !important;
    text-transform: uppercase;
  }

  ul.dropdown-menu.show {
    top: 20px !important;
  }

}


@media (max-width: 767.98px) {
  #__BVID__24__BV_toggle_ {
    font-size: 1.75rem;
  }

  .btn-secondary:not(:disabled):not(.disabled), .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    font-size: 28px !important;
  }

}
</style>
