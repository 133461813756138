<template>
  <div>

    <div class="footer" :style="{'background-color': sfondo_1 }">

      <div v-if="texture" class="texture">
        <img :src="texture" alt="">
      </div>

      <div class="container-80">
        <div class="info-footer">
          <b-row>
            <b-col class="section-left" md="4" :style="{'border-right': '1px solid' + testo_sfondo_1}">
              <h3 class="footer-agency-name" :style="{'color':testo_sfondo_1}">
                {{ nome_azienda }}</h3>
              <h4 class="footer-agency-address" :style="{'color':testo_sfondo_1}">
                {{ via }} - {{ cap }}
                {{ comune }}</h4>
              <span
                  class="footer-agency-iva"
                  :style="{'color':testo_sfondo_1}">
                P.iva {{
                  partita_iva
                }}</span>
            </b-col>
            <b-col md="4" class="text-color">
              <ul class="footer-agency-info">
                <li>
                  <router-link to="/contatti" :style="{'color': testo_sfondo_1 + '!important'}">
                    Contatti
                  </router-link>
                </li>
                <li>
                  <router-link to="/chi-siamo" :style="{'color': testo_sfondo_1 + '!important'}">
                    Chi siamo
                  </router-link>
                </li>
                <li>
                  <router-link to="" :style="{'color': testo_sfondo_1 + '!important'}">Servizi
                  </router-link>
                </li>
                <li>
                  <router-link to="/manifesti" :style="{'color': testo_sfondo_1 + '!important'}">Necrologi
                  </router-link>
                </li>
              </ul>
            </b-col>
            <b-col md="4" class="text-color">

            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="realized container-80">
      <div class="funer24">
        <span id="title-realized">Realizzato da:</span>
        <span id="funer">Funer</span>
        <span id="twenty-four">24</span>
      </div>
      <div>
        <span class="termini-servizi">Termini e Servizi</span>
        <span class="privacy">Privacy</span>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "Footer",
  computed: {

    ...mapGetters([
      'nome_azienda',
      'via',
      'cap',
      'comune',
      'partita_iva',
      'texture',
      'testo_sfondo_1',
      'testo_sfondo_2',
      'testo_sfondo_2',
      'sfondo_1',
      'sfondo_2',
      'sfondo_3',
    ]),

  },
}
</script>


<style scoped>
@import url('https://fonts.cdnfonts.com/css/qumpellkano12');

@font-face {
  font-family: 'qumpellkano12regular';
  src: url('~@/assets/fonts/qumpellkano12.woff2') format('woff2'),
  url('~@/assets/fonts/qumpellkano12.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.footer {
  position: relative;
}

.container-80 {
  width: 80%;
  margin: auto;
  color: black !important;
}

.info-footer {
  padding: 40px 0 40px 0;
}

.section-left {
  max-width: 32.5% !important;
}

.footer-agency-name {
  font-size: 20px;
  /* color: white; */
}

.footer-agency-address {
  font-size: 16px;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 40px;
  /* color: #8284BB; */
}

.footer-agency-iva {
  font-size: 16px;
  /* color: #8284BB; */
}

.footer-agency-info {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.footer-agency-info > li:after {
  display: none;
}

.texture > img {
  position: absolute;
  height: 100%;
  /* opacity: 0.1; */
  width: 100%;
  object-fit: cover;
}

.realized {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}

.funer24 {
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: white;
}

#title-realized {
  font-size: 10px;
  color: black;
}

#funer {
  font-size: 20px;
  font-family: 'qumpellkano12regular';
  color: black;
}

#twenty-four {
  font-size: 20px;
  font-family: 'qumpellkano12regular';
  color: #581191;
}

.privacy {
  margin-left: 20px;
}

.termini-servizi, .privacy {
  font-size: 15px;
}


@keyframes moveInleft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  80% {
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}


@media (max-width: 1023px) {
  .nav {
    display: none !important;
  }

  .hmg-menu {
    display: block !important;
  }

  .icon-hamburger {
    width: 25px;
  }

  .item-visibility-none {
    display: none;
  }

  .item-visibility-block {
    display: block;
  }

  .full-screen-menu {
    /* background-image: linear-gradient(to top, #1C1565, #2E3192); */
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 10000;
    color: white;
    text-align: end;
    padding-right: 10%;
    padding-top: 27.5px;
    text-transform: uppercase;

  }

  #all-item-menu > h3 {
    margin-top: 30px;
  }

  .icon-telephone-navbar {
    width: 50px;
    height: 50px;
    margin-top: 30px;
  }
}

@media (max-width: 767.98px) {

  .section-left {
    border-right: none !important;
    max-width: 90.5% !important;
  }

  .footer-agency-name {
    font-size: 18px;
  }

  .footer-agency-address {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .footer-agency-info {
    display: none;
  }
}

@media (max-width: 470px) {
  .termini-servizi {
    font-size: 10px;
  }

  .privacy {
    font-size: 10px;
  }
}

</style>
