import Vue from 'vue'
import App from './App.vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import VueRouter from 'vue-router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import {Icon} from 'leaflet';
import router from "./router/index";
import VueMeta from 'vue-meta'
import axios from "axios"
import store from './store/index';


(async () => {


    window.axios = axios
    axios.defaults.withCredentials = false
    axios.defaults.baseURL = process.env.VUE_APP_BASEURL
    Vue.config.performance = true
    Vue.config.productionTip = true
    Vue.config.silent = false;


    if (process.env.NODE_ENV === 'develop') {

        axios.interceptors.request.use(function (config) {
            config.headers.common = {
                Authorization: 'Token ' + process.env.VUE_APP_TOKEN_HEADER,
                "Content-Type": "application/json",
                Accept: "application/json"
            }
            return config;
        })
    }

    if (process.env.NODE_ENV === 'production') {

        const get_token = '/website/api/v0/token/get?domain=' + document.location.hostname.replace('www.', '');
        let res = await axios.get(get_token);
        if (res.status === 200) {
            console.log(res.data)
            let token = res.data.token;
            let state = res.data.state;
            if(state === 'OFFLINE') console.log("OFFLINE STATUS")
            if (token.length > 0 && state === 'ONLINE') {
                axios.interceptors.request.use(function (config) {
                    config.headers.common = {
                        Authorization: 'Token ' + token,
                        "Content-Type": "application/json",
                        Accept: "application/json"
                    }
                    return config;
                })
            }
        }
    }

    // ---------------------------------------------------------------------------------------------------------
    Vue.prototype.$URL = 'https://api.mapbox.com/styles/v2/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFyb2NjbzEyIiwiYSI6ImNsazc1ejNyaDA3NzkzdHA2cmppNWN4MzUifQ.PGAGDXVwWq6njX4L3EUw7w'
    Vue.prototype.$ZOOM = 17;
    Vue.prototype.$API_LIST_MANIFESTI = '/dashboard/api/v0/manifesti/website/list';
    Vue.prototype.$API_DETTAGLIO_MANIFESTO = '/dashboard/api/v0/manifesti/get/';
    Vue.prototype.$API_LIST_NECROLOGI = '/dashboard/api/v0/necrologi/list';
    Vue.prototype.$API_DETTAGLIO_NECROLOGIO = '/dashboard/api/v0/necrologi/get/';
    Vue.prototype.$API_MANIFESTI_FROM_ID_NECROLOGIO = '/api/app/v0/retrieve/manifesti/from/necrologio/';
    Vue.prototype.$API_PENSIERI_DEL_CUORE = '/dashboard/api/v0/necrologi/pensiericuore/list';
    // ---------------------------------------------------------------------------------------------------------

    delete Icon.Default.prototype._getIconUrl;
    Icon.Default.mergeOptions({
        iconRetinaUrl: require('@/assets/marker/marker_agenzia.png'),
        iconUrl: require('@/assets/marker/marker_agenzia.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });

    Vue.use(BootstrapVue)
    Vue.use(IconsPlugin)
    Vue.use(VueRouter)
    Vue.use(VueMeta)

    Vue.component('l-map', LMap);
    Vue.component('l-tile-layer', LTileLayer);
    Vue.component('l-marker', LMarker);

    new Vue({
        async beforeCreate() {
            await store.dispatch('listFAQ')
            await store.dispatch('init')
        },
        router,
        store,
        render: h => h(App),

    }).$mount('#app')

})
();

