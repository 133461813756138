<template>
  <div>
    <div class="navbar-mock2">

      <!-- Navbar Mobile -->
      <div class="number-agency-mock2-mobile" :style="{ 'background-color': sfondo_1, 'color': testo_sfondo_1 }">
        <div v-if="texture" class="texture-navbar">
          <img :src="texture" alt="">
        </div>
        <Telephone
            :style="{'fill': testo_sfondo_1 }"
            class="icon-telephone-mock2"
        >
        </Telephone>
        <span class="number-header-mock2" @click="callNumberSelect(telefono)">{{ telefono }}</span>
      </div>
      <div id="hmg-icon-mock2"
           class="hmg-mock2" :style="{ 'background-color': sfondo_3}">
        <MenuHamburger
            :style="{'fill': 'black'}"
            class="hmg-position-mock2"
            @click="toogleMenu">
        </MenuHamburger>
        <div @click="toogleMenu" id="all-item-menu" class="item-visibility-none menu-mock2"
             :style="{'color': testo_sfondo_1 + '!important'}">
          <h3>
            <router-link :style="{'color': testo_sfondo_1 + '!important'}" to="/">Home</router-link>
          </h3>
          <h3>
            <router-link :style="{'color': testo_sfondo_1 + '!important'}" to="/chi-siamo">Chi siamo</router-link>
          </h3>
          <h3>
            <router-link :style="{'color': testo_sfondo_1 + '!important'}" to="/manifesti">Necrologi</router-link>
          </h3>
          <h3 v-if="faq !== null && faq.length > 0">
            <router-link :style="{'color': testo_sfondo_1 + '!important'}" to="/faq">FAQ</router-link>
          </h3>
          <h3>
            <router-link :style="{'color': testo_sfondo_1 + '!important'}" to="/contatti">Contatti</router-link>
          </h3>
          <h3 v-if="casa_funeraria.length > 0">
            <b-dropdown
                text="Case Funerarie"
                class="custom-dropdown-case-funerarie nav-casa-funeraria-mobile"
                right
            >
              <b-dropdown-item :style="{'color': testo_sfondo_1 + '!important'}"
              >
                <router-link v-for="item in casa_funeraria" :key="item.id"
                             class="nav-casa-funeraria"
                             :to="`/casa-funeraria/${item.id}`">Casa Funeraria {{ item.nome }}
                </router-link>
              </b-dropdown-item>
            </b-dropdown>
          </h3>
        </div>
      </div>

      <!-- Navbar Mobile -->


      <!-- Navbar Desktop -->
      <div class="number-agency-mock2" :style="{ backgroundImage: createHeasderBcg, color: testo_sfondo_1 }">
        <div v-if="texture" class="texture-navbar">
          <img :src="texture" alt="">
        </div>
        <Telephone
            :style="{'fill': testo_sfondo_1 }"
            class="icon-telephone-mock2"
        >
        </Telephone>
        <span class="number-header-mock2" @click="callNumberSelect(telefono)">{{ telefono }}</span>
      </div>
      <div class="navbar-menu-mock2" :style="{ 'backgroundColor': sfondo_3 + '!important' }">
        <ul class="nav-mock2" :style="{'color': testo_sfondo_3 + '!important'}">
          <li :style="{color: testo_sfondo_3 }">
            <router-link class="nav-home" :style="{'color': testo_sfondo_3 + '!important'}"
                         to="/">Home
            </router-link>
          </li>
          <li>
            <router-link :style="{'color': testo_sfondo_3 + '!important'}"
                         to="/chi-siamo">Chi siamo
            </router-link>
          </li>
          <li v-if="casa_funeraria.length > 0">
            <b-dropdown
                :style="{'color': testo_sfondo_3 + '!important'}"
                text="CASA FUNERARIA"
                class="custom-dropdown-case-funerarie"
            >
              <b-dropdown-item>
                <router-link v-for="item in casa_funeraria" :key="item.id"
                             :style="{'color': testo_sfondo_3 + '!important'}"
                             class="nav-casa-funeraria"
                             :to="`/casa-funeraria/${item.id}`">Casa Funeraria {{ item.nome }}
                </router-link>
              </b-dropdown-item>
            </b-dropdown>
          </li>
          <li>
            <router-link :style="{'color': testo_sfondo_3 + '!important'}"
                         to="/manifesti">Necrologi
            </router-link>
          </li>
          <li v-if="faq !== null && faq.length > 0">
            <router-link :style="{'color': testo_sfondo_3 + '!important'}"
                         to="/faq">Faq
            </router-link>
          </li>
          <li>
            <router-link :style="{'color': testo_sfondo_3 + '!important'}"
                         to="/contatti">Contatti
            </router-link>
          </li>
        </ul>
      </div>
      <div class="search-mock2" :style="{ backgroundColor: sfondo_2 }">
        <!-- <img class="lente-navbar" src="~@/assets/TemplateImg/lente.svg" alt=""> -->
      </div>
      <!-- Navbar desktop -->
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import MenuHamburger from '@/assets/TemplateImg/menu.svg?inline'
import Telephone from '@/assets/TemplateImg/iconTel.svg?inline'


export default {
  name: "Header",
  components: {
    MenuHamburger,
    Telephone
  },
  data() {
    return {
      element: null,
      element_visibility: null,
      isMobileVersion: null,
      logo_agency_header: null,
      shrinkOn: null,
      distanceY: null,
      headerEl: null,
    }
  },
  computed: {
    ...mapGetters([
      'casa_funeraria',
      'logo_azienda',
      'flag_case_funerarie',
      'faq',
      'telefono',
      'texture',
      'sfondo_1',
      'sfondo_2',
      'sfondo_3',
      'testo_sfondo_1',
      'testo_sfondo_2',
      'testo_sfondo_3',
    ]),

    createHeasderBcg() {
      return `linear-gradient(90deg, ${this.sfondo_1} 80%, ${this.sfondo_2} 20%)`;
    },
  },

  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {

    toogleMenu: function () {
      this.element = document.getElementById("hmg-icon-mock2");
      this.logo_agency_header = document.getElementById("logo-agency-header");
      this.element.classList.toggle("full-screen-menu-mock2");
      this.element.classList.toggle("hmg-mock2");
      this.element_visibility = document.getElementById("all-item-menu");
      this.element_visibility.classList.toggle("item-visibility-block");
      this.element_visibility.classList.toggle("item-visibility-none");
      this.isMobileVersion = document.getElementsByClassName('full-screen-menu-mock2');
      if (this.isMobileVersion.length > 0) {
        document.getElementById("hmg-icon-mock2").style.backgroundColor = this.sfondo_1;
      } else {
        document.getElementById("hmg-icon-mock2").style.backgroundColor = this.sfondo_3;
      }
    },

    // handleScroll() {
    //   if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
    //     document.getElementById("scroll-header").style.height = "50px";
    //     document.getElementById("fix-header").style.top = "0px";

    //     if (document.getElementById("logo-agency-header")) {
    //       document.getElementById("logo-agency-header").classList.add("logo-agency-scroll");
    //       document.getElementById("logo-agency-header").classList.remove("logo-agency");
    //     }

    //     if (document.getElementById("img-manifesti-background")) {
    //       document.getElementById("img-manifesti-background").style.marginTop = "0px";
    //     }

    //   } else {
    //     document.getElementById("scroll-header").style.height = "80px";
    //     document.getElementById("fix-header").style.top = "50px";

    //     if (document.getElementById("logo-agency-header")) {
    //       document.getElementById("logo-agency-header").classList.add("logo-agency");
    //       document.getElementById("logo-agency-header").classList.remove("logo-agency-scroll");
    //     }

    //     if (document.getElementById("img-manifesti-background")) {
    //       document.getElementById("img-manifesti-background").style.marginTop = "80px";
    //     }


    //   }
    // },
    callNumberSelect(telephone) {
      document.location.href = "tel:" + telephone;
    },

  },

}
</script>


<style scoped>
@import '~@/assets/css/template_altair/header.css';
</style>
