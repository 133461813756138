<template>
  <div>

    <div class="footer" :style="{'background-color': sfondo_1 }">

      <div v-if="texture" class="texture">
        <img :src="texture" alt="">
      </div>

      <div class="container-80">
        <div class="info-footer">
          <b-row>
            <b-col class="section-left" md="4" :style="{'border-right': '1px solid' + testo_sfondo_1}">
              <h3 class="footer-agency-name" :style="{'color':testo_sfondo_1}">
                {{ nome_azienda }}</h3>
              <h4 class="footer-agency-address" :style="{'color':testo_sfondo_1}">
                {{ via }} - {{ cap }}
                {{ comune }}</h4>
              <span
                  class="footer-agency-iva"
                  :style="{'color':testo_sfondo_1}">
                P.iva {{
                  partita_iva
                }}</span>
            </b-col>
            <b-col md="4" class="text-color">
              <ul class="footer-agency-info">
                <li>
                  <router-link to="/contatti" :style="{'color': testo_sfondo_1 + '!important'}">
                    Contatti
                  </router-link>
                </li>
                <li>
                  <router-link to="/chi-siamo" :style="{'color': testo_sfondo_1 + '!important'}">
                    Chi siamo
                  </router-link>
                </li>
                <li>
                  <router-link to="" :style="{'color': testo_sfondo_1 + '!important'}">Servizi
                  </router-link>
                </li>
                <li>
                  <router-link to="/manifesti" :style="{'color': testo_sfondo_1 + '!important'}">Necrologi
                  </router-link>
                </li>
              </ul>
            </b-col>
            <b-col md="4" class="text-color">

            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="realized container-80">
      <div class="funer24">
        <span id="title-realized">Realizzato da:</span>
        <span id="funer">Funer</span>
        <span id="twenty-four">24</span>
      </div>
      <div>
        <span class="termini-servizi">Termini e Servizi</span>
        <span class="privacy">Privacy</span>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "Footer",
  computed: {

    ...mapGetters([
      'nome_azienda',
      'via',
      'cap',
      'comune',
      'partita_iva',
      'texture',
      'testo_sfondo_1',
      'testo_sfondo_2',
      'testo_sfondo_2',
      'sfondo_1',
      'sfondo_2',
      'sfondo_3',
    ]),

  },
}
</script>


<style scoped>
@import '~@/assets/css/template_vega/header.css';
</style>
