import axios from "axios";
import Vuex from 'vuex'
import Vue from "vue";

//const CDN_URL = process.env.VUE_APP_CDN_URL;

Vue.use(Vuex)

const API_GET_AGENZIA = '/dashboard/api/v0/azienda/view';
const API_GET_SEDI = '/dashboard/api/v0/azienda/sedi/view';
const API_GET_CELLULARI_AGENZIA = '/dashboard/api/v0/azienda/cellulari/view';
const API_ICONE_SERVIZI = '/dashboard/api/v0/websites/iconeservizi/list';
const API_LIST_FAQ = '/dashboard/api/v0/websites/faq/list';
const API_LIST_MANIFESTI = '/dashboard/api/v0/manifesti/website/list' + "?page=1";
const API_LIST_NECROLOGI = '/dashboard/api/v0/necrologi/list' + "?page=1";
const API_LIST_SERVICE_ECOMMERCE = '/dashboard/api/v0/ecommerce/view/service?slug='
//const LIST_STANZE_FUNERARIE = '/dashboard/api/v0/stanzafunerarie/list?casa_funeraria=';
const LIST_CASE_FUNERARIA = '/dashboard/api/v0/casafuneraria/list';


export default new Vuex.Store({

    state: {
        id: null,
        sedi: null,
        casa_funeraria: [],
        stanze_funerarie: [],
        icone: null,
        necrologi: null,
        necrologi_counter: null,
        manifesti: null,
        manifesti_counter: null,
        cellulari: [],
        faq: null,
        foto_homepage: null,
        template: null,
        texture: null,
        foto_azienda: null,
        sfondo_1: null,
        sfondo_2: null,
        sfondo_3: null,
        testo_sfondo_1: null,
        testo_sfondo_2: null,
        testo_sfondo_3: null,
        nome_azienda: null,
        logo_azienda: null,
        partita_iva: null,
        slug: null,
        via: null,
        geolocation: null,
        fotoagenzia_set: null,
        telefono: null,
        sito_web: null,
        copertina: null,
        email: null,
        chi_siamo: null,
        comuni: null,
        cap: null,
        flag_case_funerarie: false,
        flag_faq: false,
        // ECOMMERCE
        ecommerce: [],
        telegramma: false,
        fiori: false,
        pasto: false,
        partecipazione: false,
    },

    mutations: {
        SET_AGENCY(state, result) {

            state.id = result.id;
            state.flag_case_funerarie = result.flag_case_funerarie;
            state.flag_faq = result.flag_faq;

            result.texture !== null ?
                state.texture = result.texture.img_svg : state.texture = null
            result.template == null ?
                state.template = 1 : state.template = result.template.id
            result.template == null ?
                localStorage.setItem('template', 1) : localStorage.setItem('template', result.template.id)

            state.sfondo_1 = result.sfondo_1;
            state.sfondo_2 = result.sfondo_2;
            state.sfondo_3 = result.sfondo_3;
            state.testo_sfondo_1 = result.testo_sfondo_1;
            state.testo_sfondo_2 = result.testo_sfondo_2;
            state.testo_sfondo_3 = result.testo_sfondo_3;

            state.nome_azienda = result.nome_azienda;
            state.logo_azienda = result.logo_azienda;
            state.via = result.via;
            state.telefono = result.telefono;
            state.sito_web = result.sito_web;
            state.copertina = result.copertina;
            state.email = result.email;
            state.geolocation = result.geolocation;
            state.partita_iva = result.partita_iva;
            state.chi_siamo = result.chi_siamo;
            state.comuni = result.comuni;
            state.cap = result.cap;
            state.slug = result.slug;
            state.servizi_scelti = result.servizi_scelti;
            state.fotoagenzia_set = result.fotoagenzia_set;

            axios.get(API_LIST_SERVICE_ECOMMERCE + result.slug)
                .then((res) => {
                    if (res.data.count > 0) {
                        for (const s of res.data.results) {
                            for (let key in s) {
                                if (s[key] !== null) {
                                    if (key === 'telegramma') state.telegramma = true;
                                    if (key === 'fiori') state.fiori = true;
                                    if (key === 'pasto') state.pasto = true;
                                    if (key === 'partecipazione') state.partecipazione = true;
                                    state.ecommerce.push({
                                        [key]: s[key]
                                    })
                                }
                            }
                        }
                    }
                })
                .catch((e) => {
                    console.log("Error: ", e)
                })

        },


        SET_SEDI(state, result) {
            state.sedi = result;
        },
        SET_ICONE(state, result) {
            state.icone = result;
        },
        SET_FAQ(state, result) {
            state.faq = result;
        },
        SET_NECROLOGI(state, result) {
            state.necrologi = result.results;
            state.necrologi_counter = result.count;
        },
        SET_MANIFESTI(state, result) {
            state.manifesti = result.results;
            state.manifesti_counter = result.count;
        },
        SET_CELLULARI(state, result) {
            let id = 0
            for (let k in result) {
                state.cellulari.push({
                    'id': id,
                    'cellulare': result[k].cellulare,
                    'referente': result[k].referente
                });
                id++;
            }
        },
        SET_FOTOHOMEPAGE(state, result) {
            // TODO: doesn't work algorithm for save data to R2 return single image
            state.foto_homepage = [];

                axios.get('https://upload.uploadcare.com/group/info/?pub_key=' +
                    process.env.VUE_APP_UPLOADCARE_KEY + '&group_id=' +
                    result.replace('https://ucarecdn.com/', '').replace('/', ''))
                    .then((response) => {
                        if (response.status === 200) {
                            state.foto_homepage = [];
                            let index = 0;
                            for (const obj of response.data.files) {
                                let img = 'https://ucarecdn.com/'
                                state.foto_homepage.push({
                                    'id': index++,
                                    'img': img + obj.uuid + "/" + "-/crop/16:9/-/format/auto/"
                                })
                            }
                        }
                    }, (error) => {
                        console.log("error SET_FOTOHOMEPAGE", error);
                    });

            
            /*
            if (state.fotoagenzia_set !== undefined && state.fotoagenzia_set[0].foto_homepage !== undefined) {
                for (const [key, value] of Object.entries(state.fotoagenzia_set[0].foto_homepage)) {
                    state.foto_homepage.push({
                        'id': parseInt(key),
                        'img': CDN_URL + value
                    })
                }
            } else {

                axios.get('https://upload.uploadcare.com/group/info/?pub_key=' +
                    process.env.VUE_APP_UPLOADCARE_KEY + '&group_id=' +
                    result.replace('https://ucarecdn.com/', '').replace('/', ''))
                    .then((response) => {
                        if (response.status === 200) {
                            state.foto_homepage = [];
                            let index = 0;
                            for (const obj of response.data.files) {
                                let img = 'https://ucarecdn.com/'
                                state.foto_homepage.push({
                                    'id': index++,
                                    'img': img + obj.uuid + "/" + "-/crop/16:9/-/format/auto/"
                                })
                            }
                        }
                    }, (error) => {
                        console.log("error SET_FOTOHOMEPAGE", error);
                    });
            }
            */
        },
        SET_FOTOAZIENDA(state, result) {
            state.foto_azienda = [];

                axios.get('https://upload.uploadcare.com/group/info/?pub_key=' +
                    process.env.VUE_APP_UPLOADCARE_KEY + '&group_id=' +
                    result.replace('https://ucarecdn.com/', '').replace('/', ''))
                    .then((response) => {
                        if (response.status === 200) {
                            state.foto_azienda = [];
                            let index = 0;
                            for (const obj of response.data.files) {
                                let img = 'https://ucarecdn.com/'
                                state.foto_azienda.push({
                                    'id': index++,
                                    'img': img + obj.uuid + "/" + "-/crop/16:9/-/format/auto/"
                                })
                            }
                        }
                    }, (error) => {
                        console.log(error);
                    });
            
            /*
            if (state.fotoagenzia_set !== undefined && state.fotoagenzia_set[0].foto_azienda !== undefined) {
                for (const [key, value] of Object.entries(state.fotoagenzia_set[0].foto_azienda)) {
                    state.foto_azienda.push({
                        'id': parseInt(key),
                        'img': CDN_URL + value
                    })
                }
            } else {

                axios.get('https://upload.uploadcare.com/group/info/?pub_key=' +
                    process.env.VUE_APP_UPLOADCARE_KEY + '&group_id=' +
                    result.replace('https://ucarecdn.com/', '').replace('/', ''))
                    .then((response) => {
                        if (response.status === 200) {
                            state.foto_azienda = [];
                            let index = 0;
                            for (const obj of response.data.files) {
                                let img = 'https://ucarecdn.com/'
                                state.foto_azienda.push({
                                    'id': index++,
                                    'img': img + obj.uuid + "/" + "-/crop/16:9/-/format/auto/"
                                })
                            }
                        }
                    }, (error) => {
                        console.log(error);
                    });
            }
            */

        },
        SET_TEXTURE(state, result) {
            if (result.img_svg) {
                state.texture = result.img_svg
            } else {
                state.texture = null;
            }
        },
        SET_TEMPLATE(state, result) {
            if (result) {
                state.template = result.id
                console.log("SET TEMPLATE ID TO..", result.id)
            } else {
                state.template = null;
            }
        },

        SET_CASE_FUNERARIA(state, result) {
            state.casa_funeraria = result;
        },
        SET_STANZE_FUNERARIE(state, result) {
            state.stanze_funerarie = result;
        },
    },

    getters: {

        cellulari: (state) => state.cellulari,
        manifesti: (state) => state.manifesti,
        manifesti_counter: (state) => state.manifesti_counter,
        necrologi: (state) => state.necrologi,
        necrologi_counter: (state) => state.necrologi_counter,
        flag_faq: (state) => state.flag_faq,
        flag_case_funerarie: (state) => state.flag_case_funerarie,
        sfondo_1: (state) => state.sfondo_1,
        sfondo_2: (state) => state.sfondo_2,
        sfondo_3: (state) => state.sfondo_3,
        testo_sfondo_1: (state) => state.testo_sfondo_1,
        testo_sfondo_2: (state) => state.testo_sfondo_2,
        testo_sfondo_3: (state) => state.testo_sfondo_3,
        nome_azienda: (state) => state.nome_azienda,
        partita_iva: (state) => state.partita_iva,
        via: (state) => state.via,
        telefono: (state) => state.telefono,
        sito_web: (state) => state.sito_web,
        copertina: (state) => state.copertina,
        geolocation: (state) => {
            let geolocationArray = state.geolocation.split(",")
            const longitude = parseFloat(geolocationArray[0])
            const latitude = parseFloat(geolocationArray[1])
            return [longitude, latitude];
        },
        chi_siamo: (state) => state.chi_siamo,
        comune: (state) => {
            if (state.comuni !== null) {
                return state.comuni.comune
            }
            return null;
        },
        cap: (state) => {
            if (state.comuni !== null) {
                return state.comuni.cap;
            }
            return null;
        },
        email: (state) => state.email,
        logo_azienda: (state) => state.logo_azienda,
        texture: (state) => state.texture,
        template: (state) => state.template,
        foto_azienda: (state) => state.foto_azienda,
        foto_homepage: (state) => state.foto_homepage,
        faq: (state) => state.faq,
        sedi: (state) => state.sedi,
        icone: (state) => state.icone,
        ecommerce: (state) => state.ecommerce,
        telegramma: (state) => state.telegramma,
        partecipazione: (state) => state.partecipazione,
        fiori: (state) => state.fiori,
        pasto: (state) => state.pasto,
        slug: (state) => state.slug,
        servizi(state) {

            let serviziScelti = state.servizi_scelti
            if (serviziScelti !== null && serviziScelti.length > 0) {
                let services = {}
                for (const sottoservizi of serviziScelti) {
                    services[sottoservizi.servizio.nome] = [];
                }
                for (const sottoservizi of serviziScelti) {
                    services[sottoservizi.servizio.nome].push(sottoservizi.nome);
                }
                return services;
            }

            return [];
        },
        casa_funeraria: (state) => state.casa_funeraria,
        stanze_funerarie: (state) => state.stanze_funerarie,
    },

    actions: {

        async listFAQ({commit}) {
            let faq = await axios.get(API_LIST_FAQ);
            if (faq.status === 200 && faq.data.count > 0) {
                let results = faq.data.results;
                if (faq.data.count > 0) {
                    commit('SET_FAQ', results)
                }
            }
        },

        init({commit}) {

            const req = [
                axios.get(API_GET_AGENZIA),
                axios.get(API_GET_SEDI),
                axios.get(API_ICONE_SERVIZI),
                axios.get(API_GET_CELLULARI_AGENZIA),
                axios.get(API_LIST_MANIFESTI),
                axios.get(API_LIST_NECROLOGI),
                axios.get(LIST_CASE_FUNERARIA),
            ]

            Promise.all(req).then((values) => {
                return [
                    values[0].data.results[0],
                    values[1].data.results,
                    values[2].data.results,
                    values[3].data.results,
                    values[4].data,
                    values[5].data,
                    values[6].data.results,
                ]
            }).then((res) => {
                console.log(res)
                const agenzia = res[0];
                const sedi = res[1];
                const icone_servizi = res[2];
                const cellulari = res[3];
                const case_funerarie = res[6];

                commit('SET_AGENCY', agenzia)
                commit('SET_FOTOHOMEPAGE', agenzia.foto_homepage)
                commit('SET_FOTOAZIENDA', agenzia.foto_azienda)
                commit('SET_SEDI', sedi)
                commit('SET_ICONE', icone_servizi)
                commit('SET_CELLULARI', cellulari)
                commit('SET_MANIFESTI', res[4])
                commit('SET_NECROLOGI', res[5])
                commit('SET_CASE_FUNERARIA', case_funerarie)

            }).catch((e) => {
                console.log("Exception in init..", e)
            });
        },
    },
})
