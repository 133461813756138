<template>
  <div id="app">
    <HeaderSirio v-if="template === 1"></HeaderSirio>
    <HeaderAltair v-if="template === 2"></HeaderAltair>
    <HeaderVega v-if="template === 3"></HeaderVega>
    <router-view/>
    <FooterSirio v-if="template === 1"/>
    <FooterAltair v-if="template === 2"/>
    <FooterVega v-if="template === 3"/>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

import HeaderSirio from './components/Header.vue';
import HeaderAltair from './components/template_altair/Header.vue';
import HeaderVega from './components/template_vega/Header.vue';
import FooterSirio from './components/Footer';
import FooterAltair from './components/template_altair/Footer.vue';
import FooterVega from './components/template_vega/Footer.vue';


export default {
  name: 'App',
  computed: {
    ...mapGetters([
      'template',
    ]),
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    FooterSirio,
    // eslint-disable-next-line vue/no-unused-components
    HeaderSirio,
    // eslint-disable-next-line vue/no-unused-components
    FooterAltair,
    // eslint-disable-next-line vue/no-unused-components
    HeaderAltair,
    HeaderVega,
    FooterVega,
  },
}

</script>

<style scoped>

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

#app {
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>


